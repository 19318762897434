header {
    background-color: #494747;
    height: 6em;
    padding: 20px;
    color: white;
    text-align: center;
    border-bottom: 3px solid #e40dae;
}

header h1 {
    margin: 0;
    padding: 0;
    font-size: 5em;
    font-family: fantasy;
}

.App {
    width: 80%;
    margin: 0 auto;
}

.qrow-img {    
    position: absolute;
    bottom: 0;
    right:0;
    height: 30%;    
}